<template>
  <div>
    <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

    <b-card>

        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
          <b-col md=12>

            <div class="demo-inline-spacing float-right ">
              
                <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  :to="{ name: 'daily-checklist-locations'}"
                >

                <!-- <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  @click="openLocations()"
                > -->

                <feather-icon
                    icon="TargetIcon"
                    class="mr-25"
                  />
                  <span>Locations</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        
        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> Daily Supervisor Checklist</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          <b-col md="6">
              
              <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="form.site" @change="siteChange()">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="4" >

            <b-form-group
              label="Date"
              class="required"

            > 
              
              <b-form-datepicker
              
                v-model="form.date"
                @input="siteChange()"
                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                locale="en"
              />

                
            </b-form-group>
          </b-col>
          
          
        </b-row> 
    
      </b-card>
      <b-card>
        <b-form @submit="formSubmit">

        <b-row>

            <b-col md="1">

              <h5 class="text-left">No.</h5>

            </b-col>

            <b-col md="3">

              <h5 class="text-left">Location</h5>

            </b-col>

            <b-col md="1">

              <h5 class="text-left">Time</h5>

            </b-col>
            <b-col md="2">

              <h5 class="text-left">Condition</h5>

            </b-col>
            <b-col md="2">

              <h5 class="text-left">Supervisor</h5>

            </b-col>
            <b-col md="2">

              <h5 class="text-left">Remarks</h5>

            </b-col>

            <b-col md="1">

              <b-button
                    variant="warning"
                    class="mt-0 ml-50 mr-0 float-right"
                    @click="addMore()"
                    v-if="form.site != ''"
                  >
                  <feather-icon icon="PlusIcon" />
                  
                </b-button>
              </b-col>

            </b-col>
        </b-row>
        <hr>
        
        <div v-if="form.checklistData.length == 0">
          <b-row >

              <b-col md="12">

                <h5>No Record Found</h5>

              </b-col>  
          </b-row>

          <hr>

        </div>

        <div v-if="form.checklistData.length > 0">
          <b-row class="mb-1" v-for="(chk,index) in form.checklistData" :key="index">

            <b-col md="1">

              <p class="text-left">{{index + 1}}</p>

            </b-col>

            <b-col md="3">

              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="locations"
                v-model="chk.name"
                class="w-100"
                placeholder="Select"
                :clearable="false"
              />

            </b-col>

            <b-col md="1">

              <flat-pickr
                  v-model="chk.time"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                />

            </b-col>
            <b-col md="2">

              <b-form-input 
                v-model="chk.condition"  
                placeholder="upto 500 characters"
                autocomplete="off"
                
              />

            </b-col>
            <b-col md="2">

              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="supervisors"
                v-model="chk.supervisor"
                label="full_name"
                class="w-100"
                placeholder="Select"
                :clearable="false"
              />

            </b-col>
            <b-col md="2">

              <b-form-input  
                v-model="chk.action_plan" 
                placeholder="upto 500 characters"
                autocomplete="off"
                
              />

            </b-col>

            <b-col md="1">
              <b-button
                  variant="danger"
                  class="ml-50 mr-0 float-right"
                  @click="removeData(index)"
                >
                <feather-icon icon="XIcon" />
                
              </b-button>
              

            </b-col>
          </b-row>

          <hr>
        </div>
        
        
        
        <!-- <b-row>

            <b-col
              cols="5"
              class="mb-2 mt-2"
            > 
            </b-col>
            <b-col
              cols="2"
              class="mb-2 mt-2"
            > 

              <b-row>
            
                <b-col
                  cols="12"
                  class="mb-2 mt-2"
                  ><center>
                    <h4 class="mb-0">
                      Signature
                    </h4>
                    </center>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col
                    cols="12"
                    class="mb-2 mt-0"
                  > 
                
                      <b-media class="mb-2 mt-2" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                        <template #aside>
                          <b-avatar
                            ref="supervisorS"
                            :src="doc_icon"
                            variant="light-info"
                            size="200px"
                            rounded
                          />
                        </template>

                        <div class="d-flex flex-wrap">
                          <input
                            ref="supervisorSInput"
                            type="file"
                            class="d-none"
                            @input="supervisorImageUpload"
                          >
                        </div>

                        
                      </b-media>


                      <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">

                    
                      <center>
                        <b-button
                            variant="danger"
                            class="mt-1 ml-2"
                            v-if="supervisorSign.image != null"
                            @click="removeSign('supervisor')"
                          >
                          <feather-icon icon="XIcon" />
                          
                        </b-button>
                      </center>


                    </b-col>
              </b-row>
            </b-col>
        </b-row> -->

        <b-row>
          <b-col>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="goBack()"
            >
              Cancel
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="ml-1"
              type="submit"
            >
              Submit
            </b-button>
            
          </b-col>
        </b-row>
        </b-form>

      </b-card>

      <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSiganture"
        @show="hideSiganture"
      >
        <b-row>
          <b-col md="12">

            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3 }"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3">
            <b-button
                variant="primary"
                class="mt-0"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col md="3">

            <b-button
                variant="secondary"
                class="mt-0"
                @click="useDefault()"
              >
              <span class="text-nowrap">Default</span>
            </b-button>
          </b-col>


          <b-col md="3">

              <b-button
                  variant="danger"
                  class="mt-0"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                  variant="warning"
                  class="mt-0"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
          

        </b-row>
      </b-modal>

    

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker,BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,BBreadcrumb
  },
  
  data() {
    return {
      locations:[],
      form : {
        date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
        site:''/*this.$route.params.site_id*/,
        checklistData:[
        ]
        
      },
      sites:[],
      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      supervisorSign : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      supervisors:[],
      openedSignModel:null,
      
      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1) {
                            
                    this.form.site = this.sites[0]._id;
                    this.siteChange();
                }

                return this.sites;
            }
        });
    },
    formSubmit(e){
      e.preventDefault();
      
      this.decisionAlert('Are you sure want to submit this report ?')
      .then(result => {
        if (result.value) {
          this.submitFunc();            
        }
      })
    },
    submitFunc(){
      return this.$store.dispatch(POST_API, {
           data:{
             supervisorSign:this.supervisorSign,
             items:this.form,
           },
           api: '/api/submit-daily-checklist'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                //this.showDismissibleAlert = true;
                //window.scrollTo(0,0);
                this.errorAlertCF()

            } else {
                
                this.showDismissibleAlert = false;
                
                var data = this.$store.getters.getResults.data;
                

                if (this.sites.length == 1) {        
                    this.form.site = this.sites[0]._id;
                }else{

                    this.form.site = ''/*this.$route.params.site_id*/;
                }
                this.siteChange();

                this.supervisorSign  =  {
                  image:null,
                  name:'',
                  type: 'supervisor',
                  default:'no'
                };

                this.openedSignModel = null;

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Report Submitted Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    /*this.$router.go(-1);*/
                    //console.log(data);
                    window.open(data, '_blank');
                });
                localStorage.setItem('routeCFCheck','no');
                Bus.$emit('counter_update');


                // this.$router.go(-1);
                this.$router.push({ name:'custom-forms' })
                //return data;
            }
        });
    },
    hideSiganture(){
      this.openedSignModel = null;
    },

    openSignature(type){

        if (this.openedSignModel == null) {

            this.$refs['signatureModel'].show();
            this.openedSignModel = type;
        }
      
    },
    uploadSign(){
      
      if (this.openedSignModel == 'supervisor') {

         this.$refs['supervisorSInput'].click();
      }

      if (this.openedSignModel == 'client') {

         this.$refs['clientSInput'].click();
      }

      this.$refs['signatureModel'].hide();
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{


        if (this.openedSignModel == 'supervisor') {
          
          this.supervisorSign.image = data;
          this.supervisorSign.name = 'signature.png';
          this.supervisorSign.type =  'supervisor';
          this.supervisorSign.default = 'no';

        }else{

          this.clientSign.image = data;
          this.clientSign.name = 'signature.png';

        }

        this.$refs['signatureModel'].hide();
      }
    },
    supervisorImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.supervisorSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.supervisorSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;
                this.supervisorSign.type =  'supervisor';
                this.supervisorSign.default = 'no';
                

            }
          }
      }
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Default signature not found.',
                      showConfirmButton: false,
                      timer: 1500
                    })

                }else{

                  if (this.openedSignModel == 'supervisor') {
                    
                    this.supervisorSign = {
                      image : data.default_signature,
                      name : 'siganture.png',
                      type: 'supervisor',
                      default:'yes'
                    }
                    
                    
                  }else{

                    /*this.inspectedBySign = {
                      image : data.default_signature,
                      name : 'signature.png',
                      type: 'inspected_by',
                      default:'yes'
                    }*/
                  }

                  this.$refs['signatureModel'].hide();

                }
            }
        });

    },
    removeSign(sign){

      this.supervisorSign.image = null;
      this.supervisorSign.name = '';
      this.supervisorSign.default = 'no';
    },
    getLocationData(){

      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
             date:this.form.date
           },
           api: '/api/daily-checklist-loc-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.locations = data;

                if (data.length > 0) {
                  
                  var obj = {   
                      name: '',
                      time:'',
                      condition:'',
                      supervisor:null,
                      action_plan:'',
                  };
                  this.form.checklistData = [];
                  this.form.checklistData.push(obj);
                }


                return this.locations;
            }
        });
    },
    getsupervisorData(){

      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
           },
           api: '/api/all-supervisors'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.supervisors = data;
                return this.supervisors;
            }
        });
    },
    siteChange(){

      this.form.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');

      this.form.checklistData = [];
      this.supervisors = [];

      if (this.form.site != '') {
        this.getLocationData();
        this.getsupervisorData();

      }

    },
    addMore(){
      var obj = {
                    
          name: '',
          time:'',
          condition:'',
          supervisor:null,
          action_plan:'',

      }

      this.form.checklistData.push(obj)
    },
    removeData(index){
      
      this.form.checklistData.splice(index,1);

      if (this.form.checklistData.length == 0) {
        var obj = {
                    
          name: '',
          time:'',
          condition:'',
          supervisor:null,
          action_plan:'',

      }

      this.form.checklistData.push(obj)
      }
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:null,
        text:'Daily Supervisor Checklist',
        
      }];
      return item;
    },
    openLocations(){
      this.$router.push({ name: 'hand-sanitizer-locations',params:{site_id:this.form.site != '' ? this.form.site : 'all-site',id:'all-building',form:'Daily_Supervisor_Checklist'}})
    },
    goBack(){
      if (this.form.site != '') {
          var msg = 'Do you want to Go back without saving your report?';

          this.decisionAlert(msg)
          .then(result => {
            if (result.value) {
              
              // this.$router.go(-1);
              this.$router.push({ name:'custom-forms' })

            }
          })
      }else{
          // this.$router.go(-1);   
          this.$router.push({ name:'custom-forms' })                 
      }
    }
    
  },
  mounted(){
    this.allSites();
    this.siteChange();
    
  }
  
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
